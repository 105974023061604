import {inject, Injectable} from "@angular/core";
import {Action, createSelector, Selector, State, StateContext} from "@ngxs/store";
import {ResourceTreeModel} from "../../api-services/milestone-api/models/hierarchy-definition/resource-tree.model";
import {ResourceStatusService} from "../../api-services/milestone-api/resource-status/resource-status.service";
import {append, insertItem, patch, removeItem, updateItem} from "@ngxs/store/operators";
import {LoadingState} from "../loading-state.enum";
import {ResourceStatusModel} from "../../api-services/milestone-api/resource-status/models/resource-status.model";
import {ResourceStatusStateModel} from "./models/resource-status-state.model";
import {ConnectionStatusEnum} from "../../shared/common/signalr-service-wrapper/enums/connection-status.enum";
import {tap} from "rxjs";

export class SubscribeToResourceStatus {
  public static readonly type = '[ResourceStatus] Subscribe to resource.';

  constructor(public readonly resourceTree: ResourceTreeModel | ResourceTreeModel[]) {
  }
}

export class ReceiveResourceStatus {
  public static readonly type = '[ResourceStatus] Receive resource status resource.';

  constructor(public readonly resourceTree: ResourceTreeModel, public readonly resourceStatus: ResourceStatusModel) {
  }
}

export class UnsubscribeFromResourceStatus {
  public static readonly type = '[ResourceStatus] Unsubscribe from resource.';

  constructor(public readonly resourceTree: ResourceTreeModel) {
  }
}

export class SetResourceStateConnectionStatus {
  public static readonly type = '[ResourceStatus] Set connection state.';

  constructor(public readonly connectionStatus: ConnectionStatusEnum) {
  }
}

@State<ResourceStatusStateModel>({
  name: 'resourceStatus',
  defaults: {
    connectionStatus: ConnectionStatusEnum.idle,
    resourceStatuses: []
  }
})
@Injectable()
export class ResourceStatusState {

  readonly resourceStatusService = inject(ResourceStatusService);

  static getStatusesForResources(resources: ResourceTreeModel[]) {
    return createSelector([ResourceStatusState], (state: ResourceStatusStateModel) => {
      return state.resourceStatuses.filter(x => !resources.every(y => !y.compare(x.resourceTree)));
    });
  }

  @Selector()
  static getConnectionStatus(state: ResourceStatusStateModel) {
    return state.connectionStatus;
  }

  @Action(SubscribeToResourceStatus)
  async subscribeToResourceStatus(ctx: StateContext<ResourceStatusStateModel>, action: SubscribeToResourceStatus) {
    if (action.resourceTree instanceof Array) {
      ctx.setState(patch({
        resourceStatuses: append(action.resourceTree.map(x => ({
          state: LoadingState.loading,
          resourceTree: x,
          status: null
        })))
      }));
    } else {
      ctx.setState(patch({
        resourceStatuses: insertItem({
          state: LoadingState.loading,
          resourceTree: action.resourceTree,
          status: null
        })
      }));
    }

    return this.resourceStatusService.invoke('SubscribeToResourceStatus', action.resourceTree);
  }

  @Action(ReceiveResourceStatus)
  receiveResourceStatus(ctx: StateContext<ResourceStatusStateModel>, action: ReceiveResourceStatus) {
    const currentState = ctx.getState();

    if (currentState.resourceStatuses.find(x => x.resourceTree.compare(action.resourceTree))) {
      ctx.setState(patch({
        resourceStatuses: updateItem(x => x.resourceTree.compare(action.resourceTree), patch({
          state: LoadingState.loaded,
          status: action.resourceStatus
        }))
      }));
    }
  }

  @Action(UnsubscribeFromResourceStatus)
  unsubscribeFromResource(ctx: StateContext<ResourceStatusStateModel>, action: UnsubscribeFromResourceStatus) {
    return this.resourceStatusService.invoke('UnsubscribeFromResource', action.resourceTree).pipe(
      tap(() => {
        ctx.setState(patch({
          resourceStatuses: removeItem(x => x.resourceTree.compare(action.resourceTree))
        }));
      })
    );
  }

  @Action(SetResourceStateConnectionStatus)
  setResourceStateConnectionStatus(ctx: StateContext<ResourceStatusStateModel>, action: SetResourceStateConnectionStatus) {
    ctx.setState(patch({
      connectionStatus: action.connectionStatus
    }));
  }

}

import {Injectable} from "@angular/core";
import {SignalrServiceWrapper} from "../../../shared/common/signalr-service-wrapper/signalr-service-wrapper";
import {environment} from "../../../../environments/environment";

@Injectable({providedIn: 'root'})
export class ResourceStatusService extends SignalrServiceWrapper {

  constructor() {
    super(`${environment.canaryMilestoneApiBaseUrl}/resourceStatusHub`);
  }

}
